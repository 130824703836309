@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800|Source+Sans+Pro:400,500,600);
body.compensate-for-scrollbar {
    overflow: hidden;
}

.fancybox-active {
    height: auto;
}

.fancybox-is-hidden {
    left: -9999px;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    visibility: hidden;
}

.fancybox-container {
    -webkit-backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: none;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    width: 100%;
    z-index: 99992;
}

.fancybox-container * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.fancybox-bg {
    background: rgb(30, 30, 30);
    opacity: 0;
    -webkit-transition-duration: inherit;
            transition-duration: inherit;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(.47, 0, .74, .71);
            transition-timing-function: cubic-bezier(.47, 0, .74, .71);
}

.fancybox-is-open .fancybox-bg {
    opacity: .9;
    -webkit-transition-timing-function: cubic-bezier(.22, .61, .36, 1);
            transition-timing-function: cubic-bezier(.22, .61, .36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
    direction: ltr;
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity .25s ease, visibility 0s ease .25s;
    transition: opacity .25s ease, visibility 0s ease .25s;
    visibility: hidden;
    z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
    opacity: 1;
    -webkit-transition: opacity .25s ease 0s, visibility 0s ease 0s;
    transition: opacity .25s ease 0s, visibility 0s ease 0s;
    visibility: visible;
}

.fancybox-infobar {
    color: #ccc;
    font-size: 13px;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 44px;
    left: 0;
    line-height: 44px;
    min-width: 44px;
    mix-blend-mode: difference;
    padding: 0 10px;
    pointer-events: none;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fancybox-toolbar {
    right: 0;
    top: 0;
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden;
}

.fancybox-slide {
    -webkit-backface-visibility: hidden;
    /* Using without prefix would break IE11 */
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    white-space: normal;
    width: 100%;
    z-index: 99994;
}

.fancybox-slide::before {
    content: '';
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
    display: block;
}

.fancybox-slide--image {
    overflow: hidden;
    padding: 44px 0;
}

.fancybox-slide--image::before {
    display: none;
}

.fancybox-slide--html {
    padding: 6px;
}

.fancybox-content {
    background: #fff;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
    -webkit-animation-timing-function: cubic-bezier(.5, 0, .14, 1);
            animation-timing-function: cubic-bezier(.5, 0, .14, 1);
    -webkit-backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
    cursor: -webkit-grab;
    cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
    cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.fancybox-spaceball {
    z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
    height: 100%;
    overflow: visible;
    padding: 0;
    width: 100%;
}

.fancybox-slide--video .fancybox-content {
    background: #000;
}

.fancybox-slide--map .fancybox-content {
    background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff;
}

.fancybox-video,
.fancybox-iframe {
    background: transparent;
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
    left: 0;
    position: absolute;
    top: 0;
}

.fancybox-error {
    background: #fff;
    cursor: default;
    max-width: 400px;
    padding: 40px;
    width: 100%;
}

.fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

/* Buttons */

.fancybox-button {
    background: rgba(30, 30, 30, .6);
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    padding: 10px;
    position: relative;
    -webkit-transition: color .2s;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
    color: #ccc;
}

.fancybox-button:hover {
    color: #fff;
}

.fancybox-button:focus {
    outline: none;
}

.fancybox-button.fancybox-focus {
    outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
    outline: none;
}

/* Fix IE11 */
.fancybox-button div {
    height: 100%;
}

.fancybox-button svg {
    display: block;
    height: 100%;
    overflow: visible;
    position: relative;
    width: 100%;
}

.fancybox-button svg path {
    fill: currentColor;
    stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
    display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
    display: none;
}

.fancybox-progress {
    background: #ff5268;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0;
            transform-origin: 0;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    z-index: 99998;
}

/* Close button on the top right corner of html content */

.fancybox-close-small {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #ccc;
    cursor: pointer;
    opacity: .8;
    padding: 8px;
    position: absolute;
    right: -12px;
    top: -44px;
    z-index: 401;
}

.fancybox-close-small:hover {
    color: #fff;
    opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
    color: currentColor;
    padding: 10px;
    right: 0;
    top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
    overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
    display: none;
}

/* Navigation arrows */

.fancybox-navigation .fancybox-button {
    background-clip: content-box;
    height: 100px;
    opacity: 0;
    position: absolute;
    top: calc(50% - 50px);
    width: 70px;
}

.fancybox-navigation .fancybox-button div {
    padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
    left: env(safe-area-inset-left);
    padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
    padding: 31px 6px 31px 26px;
    right: 0;
    right: env(safe-area-inset-right);
}

/* Caption */

.fancybox-caption {
    background: -webkit-gradient(linear,
        left bottom, left top,
        from(rgba(0, 0, 0, .85)),
        color-stop(50%, rgba(0, 0, 0, .3)),
        color-stop(65%, rgba(0, 0, 0, .15)),
        color-stop(75.5%, rgba(0, 0, 0, .075)),
        color-stop(82.85%, rgba(0, 0, 0, .037)),
        color-stop(88%, rgba(0, 0, 0, .019)),
        to(rgba(0, 0, 0, 0)));
    background: linear-gradient(to top,
        rgba(0, 0, 0, .85) 0%,
        rgba(0, 0, 0, .3) 50%,
        rgba(0, 0, 0, .15) 65%,
        rgba(0, 0, 0, .075) 75.5%,
        rgba(0, 0, 0, .037) 82.85%,
        rgba(0, 0, 0, .019) 88%,
        rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    color: #eee;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    line-height: 1.5;
    padding: 75px 44px 25px 44px;
    pointer-events: none;
    right: 0;
    text-align: center;
    z-index: 99996;
}

@supports (padding: max(0px)) {
    .fancybox-caption {
        padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left));
    }
}

.fancybox-caption--separate {
    margin-top: -50px;
}

.fancybox-caption__body {
    max-height: 50vh;
    overflow: auto;
    pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
    color: #ccc;
    text-decoration: none;
}

.fancybox-caption a:hover {
    color: #fff;
    text-decoration: underline;
}

/* Loading indicator */

.fancybox-loading {
    -webkit-animation: fancybox-rotate 1s linear infinite;
            animation: fancybox-rotate 1s linear infinite;
    background: transparent;
    border: 4px solid #888;
    border-bottom-color: #fff;
    border-radius: 50%;
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    opacity: .7;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes fancybox-rotate {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

/* Transition effects */

.fancybox-animated {
    -webkit-transition-timing-function: cubic-bezier(0, 0, .25, 1);
            transition-timing-function: cubic-bezier(0, 0, .25, 1);
}

/* transitionEffect: slide */

.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */

.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
    opacity: 0;
    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
            transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
    opacity: 1;
}

/* transitionEffect: zoom-in-out */

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
            transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(.5, .5, .5);
            transform: scale3d(.5, .5, .5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */

.fancybox-fx-rotate.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

/* transitionEffect: circular */

.fancybox-fx-circular.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
            transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
            transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
            transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */

.fancybox-fx-tube.fancybox-slide--previous {
    -webkit-transform: translate3d(-100%, 0, 0) scale(.1) skew(-10deg);
            transform: translate3d(-100%, 0, 0) scale(.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
    -webkit-transform: translate3d(100%, 0, 0) scale(.1) skew(10deg);
            transform: translate3d(100%, 0, 0) scale(.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
    .fancybox-slide {
        padding-left: 6px;
        padding-right: 6px;
    }

    .fancybox-slide--image {
        padding: 6px 0;
    }

    .fancybox-close-small {
        right: -6px;
    }

    .fancybox-slide--image .fancybox-close-small {
        background: #4e4e4e;
        color: #f2f4f6;
        height: 36px;
        opacity: 1;
        padding: 6px;
        right: 0;
        top: 0;
        width: 36px;
    }

    .fancybox-caption {
        padding-left: 12px;
        padding-right: 12px;
    }

    @supports (padding: max(0px)) {
        .fancybox-caption {
            padding-left: max(12px, env(safe-area-inset-left));
            padding-right: max(12px, env(safe-area-inset-right));
        }
    }
}
/* Share */

.fancybox-share {
    background: #f4f4f4;
    border-radius: 3px;
    max-width: 90%;
    padding: 30px;
    text-align: center;
}

.fancybox-share h1 {
    color: #222;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.fancybox-share p {
    margin: 0;
    padding: 0;
}

.fancybox-share__button {
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    margin: 0 5px 10px 5px;
    min-width: 130px;
    padding: 0 15px;
    text-decoration: none;
    -webkit-transition: all .2s;
    transition: all .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
    color: #fff;
}

.fancybox-share__button:hover {
    text-decoration: none;
}

.fancybox-share__button--fb {
    background: #3b5998;
}

.fancybox-share__button--fb:hover {
    background: #344e86;
}

.fancybox-share__button--pt {
    background: #bd081d;
}

.fancybox-share__button--pt:hover {
    background: #aa0719;
}

.fancybox-share__button--tw {
    background: #1da1f2;
}

.fancybox-share__button--tw:hover {
    background: #0d95e8;
}

.fancybox-share__button svg {
    height: 25px;
    margin-right: 7px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 25px;
}

.fancybox-share__button svg path {
    fill: #fff;
}

.fancybox-share__input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    color: #5d5b5b;
    font-size: 14px;
    margin: 10px 0 0 0;
    outline: none;
    padding: 10px 15px;
    width: 100%;
}
/* Thumbs */

.fancybox-thumbs {
    background: #ddd;
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 2px 2px 4px 2px;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    width: 212px;
    z-index: 99995;
}

.fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
    display: block;
}

.fancybox-show-thumbs .fancybox-inner {
    right: 212px;
}

.fancybox-thumbs__list {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
    overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
    width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    border-radius: 10px;
}

.fancybox-thumbs__list a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, .1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    float: left;
    height: 75px;
    margin: 2px;
    max-height: calc(100% - 8px);
    max-width: calc(50% - 4px);
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 100px;
}

.fancybox-thumbs__list a::before {
    border: 6px solid #ff5268;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all .2s cubic-bezier(.25, .46, .45, .94);
    transition: all .2s cubic-bezier(.25, .46, .45, .94);
    z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
    opacity: .5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
    opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
    .fancybox-thumbs {
        width: 110px;
    }

    .fancybox-show-thumbs .fancybox-inner {
        right: 110px;
    }

    .fancybox-thumbs__list a {
        max-width: calc(100% - 10px);
    }
}
@charset "UTF-8";
/* General */
/* Transitions */
/* Colors */
/* Bootstrap variables */
/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.33333%;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.66667%;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.33333%;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.66667%;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.33333%;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.66667%;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -webkit-order: 13;
      -ms-flex-order: 13;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
      -ms-flex-order: 0;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: row-reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: column-reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
      -ms-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
      -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
      -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 1 auto !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -webkit-flex-grow: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -webkit-flex-grow: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
      -ms-flex-negative: 0 !important;
          flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
      -ms-flex-negative: 1 !important;
          flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -webkit-justify-content: flex-start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -webkit-justify-content: flex-end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
      -ms-flex-pack: distribute !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -webkit-align-items: flex-start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -webkit-align-items: flex-end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -webkit-align-items: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -webkit-align-items: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
      -ms-flex-line-pack: start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
      -ms-flex-line-pack: end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
      -ms-flex-line-pack: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
      -ms-flex-line-pack: justify !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
      -ms-flex-line-pack: distribute !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
      -ms-flex-line-pack: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
      -ms-flex-item-align: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
      -ms-flex-item-align: start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
      -ms-flex-item-align: end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
      -ms-flex-item-align: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
      -ms-flex-item-align: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
      -ms-flex-item-align: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 15px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 800; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #f89728;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #cd7007;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #f89728 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #e67e07 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #204294 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #172f6a !important; }

.bg-light {
  background-color: #fbffff !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c8ffff !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #f89728 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #204294 !important; }

.border-light {
  border-color: #fbffff !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 3px !important; }

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important; }

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important; }

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: row-reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: column-reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
      -ms-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
      -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
      -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 1 auto !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -webkit-flex-grow: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -webkit-flex-grow: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
      -ms-flex-negative: 0 !important;
          flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
      -ms-flex-negative: 1 !important;
          flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -webkit-justify-content: flex-start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -webkit-justify-content: flex-end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
      -ms-flex-pack: distribute !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -webkit-align-items: flex-start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -webkit-align-items: flex-end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -webkit-align-items: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -webkit-align-items: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
      -ms-flex-line-pack: start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
      -ms-flex-line-pack: end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
      -ms-flex-line-pack: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
      -ms-flex-line-pack: justify !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
      -ms-flex-line-pack: distribute !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
      -ms-flex-line-pack: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
      -ms-flex-item-align: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
      -ms-flex-item-align: start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
      -ms-flex-item-align: end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
      -ms-flex-item-align: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
      -ms-flex-item-align: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
      -ms-flex-item-align: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 15px !important; }

.mt-1,
.my-1 {
  margin-top: 15px !important; }

.mr-1,
.mx-1 {
  margin-right: 15px !important; }

.mb-1,
.my-1 {
  margin-bottom: 15px !important; }

.ml-1,
.mx-1 {
  margin-left: 15px !important; }

.m-2 {
  margin: 20px !important; }

.mt-2,
.my-2 {
  margin-top: 20px !important; }

.mr-2,
.mx-2 {
  margin-right: 20px !important; }

.mb-2,
.my-2 {
  margin-bottom: 20px !important; }

.ml-2,
.mx-2 {
  margin-left: 20px !important; }

.m-3 {
  margin: 40px !important; }

.mt-3,
.my-3 {
  margin-top: 40px !important; }

.mr-3,
.mx-3 {
  margin-right: 40px !important; }

.mb-3,
.my-3 {
  margin-bottom: 40px !important; }

.ml-3,
.mx-3 {
  margin-left: 40px !important; }

.m-4 {
  margin: 80px !important; }

.mt-4,
.my-4 {
  margin-top: 80px !important; }

.mr-4,
.mx-4 {
  margin-right: 80px !important; }

.mb-4,
.my-4 {
  margin-bottom: 80px !important; }

.ml-4,
.mx-4 {
  margin-left: 80px !important; }

.m-5 {
  margin: 120px !important; }

.mt-5,
.my-5 {
  margin-top: 120px !important; }

.mr-5,
.mx-5 {
  margin-right: 120px !important; }

.mb-5,
.my-5 {
  margin-bottom: 120px !important; }

.ml-5,
.mx-5 {
  margin-left: 120px !important; }

.m-6 {
  margin: 180px !important; }

.mt-6,
.my-6 {
  margin-top: 180px !important; }

.mr-6,
.mx-6 {
  margin-right: 180px !important; }

.mb-6,
.my-6 {
  margin-bottom: 180px !important; }

.ml-6,
.mx-6 {
  margin-left: 180px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 15px !important; }

.pt-1,
.py-1 {
  padding-top: 15px !important; }

.pr-1,
.px-1 {
  padding-right: 15px !important; }

.pb-1,
.py-1 {
  padding-bottom: 15px !important; }

.pl-1,
.px-1 {
  padding-left: 15px !important; }

.p-2 {
  padding: 20px !important; }

.pt-2,
.py-2 {
  padding-top: 20px !important; }

.pr-2,
.px-2 {
  padding-right: 20px !important; }

.pb-2,
.py-2 {
  padding-bottom: 20px !important; }

.pl-2,
.px-2 {
  padding-left: 20px !important; }

.p-3 {
  padding: 40px !important; }

.pt-3,
.py-3 {
  padding-top: 40px !important; }

.pr-3,
.px-3 {
  padding-right: 40px !important; }

.pb-3,
.py-3 {
  padding-bottom: 40px !important; }

.pl-3,
.px-3 {
  padding-left: 40px !important; }

.p-4 {
  padding: 80px !important; }

.pt-4,
.py-4 {
  padding-top: 80px !important; }

.pr-4,
.px-4 {
  padding-right: 80px !important; }

.pb-4,
.py-4 {
  padding-bottom: 80px !important; }

.pl-4,
.px-4 {
  padding-left: 80px !important; }

.p-5 {
  padding: 120px !important; }

.pt-5,
.py-5 {
  padding-top: 120px !important; }

.pr-5,
.px-5 {
  padding-right: 120px !important; }

.pb-5,
.py-5 {
  padding-bottom: 120px !important; }

.pl-5,
.px-5 {
  padding-left: 120px !important; }

.p-6 {
  padding: 180px !important; }

.pt-6,
.py-6 {
  padding-top: 180px !important; }

.pr-6,
.px-6 {
  padding-right: 180px !important; }

.pb-6,
.py-6 {
  padding-bottom: 180px !important; }

.pl-6,
.px-6 {
  padding-left: 180px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 15px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 15px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 15px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 15px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 15px !important; }
  .m-sm-2 {
    margin: 20px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 20px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 20px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 20px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 20px !important; }
  .m-sm-3 {
    margin: 40px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 40px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 40px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 40px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 40px !important; }
  .m-sm-4 {
    margin: 80px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 80px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 80px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 80px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 80px !important; }
  .m-sm-5 {
    margin: 120px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 120px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 120px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 120px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 120px !important; }
  .m-sm-6 {
    margin: 180px !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 180px !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 180px !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 180px !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 180px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 15px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 15px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 15px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 15px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 15px !important; }
  .p-sm-2 {
    padding: 20px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 20px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 20px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 20px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 20px !important; }
  .p-sm-3 {
    padding: 40px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 40px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 40px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 40px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 40px !important; }
  .p-sm-4 {
    padding: 80px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 80px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 80px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 80px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 80px !important; }
  .p-sm-5 {
    padding: 120px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 120px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 120px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 120px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 120px !important; }
  .p-sm-6 {
    padding: 180px !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 180px !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 180px !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 180px !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 180px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 15px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 15px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 15px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 15px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 15px !important; }
  .m-md-2 {
    margin: 20px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 20px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 20px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 20px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 20px !important; }
  .m-md-3 {
    margin: 40px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 40px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 40px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 40px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 40px !important; }
  .m-md-4 {
    margin: 80px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 80px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 80px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 80px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 80px !important; }
  .m-md-5 {
    margin: 120px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 120px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 120px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 120px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 120px !important; }
  .m-md-6 {
    margin: 180px !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 180px !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 180px !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 180px !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 180px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 15px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 15px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 15px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 15px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 15px !important; }
  .p-md-2 {
    padding: 20px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 20px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 20px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 20px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 20px !important; }
  .p-md-3 {
    padding: 40px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 40px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 40px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 40px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 40px !important; }
  .p-md-4 {
    padding: 80px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 80px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 80px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 80px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 80px !important; }
  .p-md-5 {
    padding: 120px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 120px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 120px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 120px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 120px !important; }
  .p-md-6 {
    padding: 180px !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 180px !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 180px !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 180px !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 180px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 15px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 15px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 15px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 15px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 15px !important; }
  .m-lg-2 {
    margin: 20px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 20px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 20px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 20px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 20px !important; }
  .m-lg-3 {
    margin: 40px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 40px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 40px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 40px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 40px !important; }
  .m-lg-4 {
    margin: 80px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 80px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 80px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 80px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 80px !important; }
  .m-lg-5 {
    margin: 120px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 120px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 120px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 120px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 120px !important; }
  .m-lg-6 {
    margin: 180px !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 180px !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 180px !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 180px !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 180px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 15px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 15px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 15px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 15px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 15px !important; }
  .p-lg-2 {
    padding: 20px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 20px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 20px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 20px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 20px !important; }
  .p-lg-3 {
    padding: 40px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 40px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 40px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 40px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 40px !important; }
  .p-lg-4 {
    padding: 80px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 80px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 80px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 80px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 80px !important; }
  .p-lg-5 {
    padding: 120px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 120px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 120px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 120px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 120px !important; }
  .p-lg-6 {
    padding: 180px !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 180px !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 180px !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 180px !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 180px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 15px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 15px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 15px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 15px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 15px !important; }
  .m-xl-2 {
    margin: 20px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 20px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 20px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 20px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 20px !important; }
  .m-xl-3 {
    margin: 40px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 40px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 40px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 40px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 40px !important; }
  .m-xl-4 {
    margin: 80px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 80px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 80px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 80px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 80px !important; }
  .m-xl-5 {
    margin: 120px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 120px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 120px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 120px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 120px !important; }
  .m-xl-6 {
    margin: 180px !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 180px !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 180px !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 180px !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 180px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 15px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 15px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 15px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 15px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 15px !important; }
  .p-xl-2 {
    padding: 20px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 20px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 20px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 20px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 20px !important; }
  .p-xl-3 {
    padding: 40px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 40px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 40px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 40px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 40px !important; }
  .p-xl-4 {
    padding: 80px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 80px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 80px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 80px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 80px !important; }
  .p-xl-5 {
    padding: 120px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 120px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 120px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 120px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 120px !important; }
  .p-xl-6 {
    padding: 180px !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 180px !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 180px !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 180px !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 180px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 800 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #f89728 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #e67e07 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #204294 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #172f6a !important; }

.text-light {
  color: #fbffff !important; }

a.text-light:hover, a.text-light:focus {
  color: #c8ffff !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 48px; }

h2, .h2 {
  font-size: 46px; }

h3, .h3 {
  font-size: 14px; }

h4, .h4 {
  font-size: 14px; }

h5, .h5 {
  font-size: 22px; }

h6, .h6 {
  font-size: 14px; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014   \A0"; }

.form-control {
  display: block;
  width: 100%;
  height: calc(42px + 2px);
  padding: 12px 12px;
  font-size: 1rem;
  line-height: 1.5;
  color: #090d2b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cbcee4;
  border-radius: 3px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #090d2b;
    background-color: #fff;
    border-color: #fcd3a3;
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .form-control::-webkit-input-placeholder {
    color: rgba(9, 13, 43, 0.75);
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: rgba(9, 13, 43, 0.75);
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: rgba(9, 13, 43, 0.75);
    opacity: 1; }
  .form-control::placeholder {
    color: rgba(9, 13, 43, 0.75);
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #090d2b;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(12px + 1px);
  padding-bottom: calc(12px + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(12px + 1px);
  padding-bottom: calc(12px + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(12px + 1px);
  padding-bottom: calc(12px + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 12px 12px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 12px 12px;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 3px; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 3px; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #204294; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(32, 66, 148, 0.9);
  border-radius: 3px; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #204294; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #204294;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 66, 148, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(32, 66, 148, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #204294; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #204294; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #597fdb; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #2955be; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(32, 66, 148, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(32, 66, 148, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #204294; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 66, 148, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(32, 66, 148, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/**
Helper classes
 */
.background-primary {
  background-color: #f89728; }
  .background-primary--faded {
    background-color: rgba(248, 151, 40, 0.07); }

.background-bubble {
  position: relative;
  overflow: hidden;
  display: block;
  z-index: 0; }
  .background-bubble:before {
    content: "";
    position: absolute;
    width: 100%;
    left: 25%;
    top: -5%;
    bottom: 0;
    background-image: url("/build/images/asset-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: .03; }

.background-cover {
  background-size: cover; }

.background-center {
  background-position: center center; }

.border-radius {
  border-radius: 3px; }

.clear-both {
  clear: both; }

.debug {
  border: 1px solid blue; }

.phone-number {
  font-weight: 800;
  color: #090d2b !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-decoration: none !important;
  width: 210px;
  display: inline-block;
  margin-bottom: 10px; }
  .phone-number span:not(.text) {
    color: #204294 !important;
    margin-right: 10px; }
  .phone-number span.text {
    font-weight: 400;
    color: #090d2b;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    text-decoration: none;
    margin-left: 20px;
    float: right; }
  .phone-number:hover, .phone-number:focus {
    color: #204294 !important; }

.service-icon {
  float: right; }
  .service-icon.md {
    font-size: 73px;
    margin-top: 30px; }
    @media (max-width: 991.98px) {
      .service-icon.md {
        font-size: 50px;
        margin-bottom: 20px; } }
  .service-icon.xl {
    font-size: 100px;
    margin-top: 50px; }
    @media (max-width: 1199.98px) {
      .service-icon.xl {
        margin-top: 0; } }

.content {
  overflow: hidden; }

.container {
  position: relative;
  z-index: 2; }

.block-animation .phone-number:first-of-type span.text {
  -webkit-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
  height: 20px; }

.block-animation .phone-number:last-of-type span.text {
  -webkit-transition: all 500ms ease-out 300ms;
  transition: all 500ms ease-out 300ms;
  height: 20px; }

.highlight:hover .block-animation .phone-number:first-of-type span.text {
  /*color: $color-1;
    text-shadow: 0 0 0px $color-1;*/
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-animation: animateText  600ms ease-out forwards;
          animation: animateText  600ms ease-out forwards; }

.highlight:hover .block-animation .phone-number:last-of-type span.text {
  /* color: $color-1;
    text-shadow: 0 0 0px $color-1;*/
  -webkit-animation: animateText 600ms ease-out 400ms forwards;
          animation: animateText 600ms ease-out 400ms forwards; }

@-webkit-keyframes animateText {
  0% {
    font-size: 14px; }
  50% {
    font-size: 16px; }
  100% {
    font-size: 14px; } }

@keyframes animateText {
  0% {
    font-size: 14px; }
  50% {
    font-size: 16px; }
  100% {
    font-size: 14px; } }

@font-face {
  font-family: 'icomoon';
  src: url(/build/fonts/icomoon.d219c352.eot);
  src: url(/build/fonts/icomoon.d219c352.eot#iefix) format("embedded-opentype"), url(/build/fonts/icomoon.e4861e71.ttf) format("truetype"), url(/build/fonts/icomoon.14d74c61.woff) format("woff"), url(/build/images/icomoon.fa2a5a79.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-linkedin-square:before {
  content: "\E915"; }

.icon-youtube-play:before {
  content: "\E916"; }

.icon-twitter1:before {
  content: "\E917"; }

.icon-instagram1:before {
  content: "\E918"; }

.icon-facebook-official:before {
  content: "\E919"; }

.icon-close:before {
  content: "\E909"; }

.icon-check:before {
  content: "\E910";
  font-size: 15px; }

.icon-btn-icon:before {
  content: "\E914"; }

.icon-van-de-velde .path1:before {
  content: "\E900";
  color: #FFF; }

.icon-van-de-velde .path2:before {
  content: "\E901";
  margin-left: -1.107421875em;
  color: #204294; }

.icon-van-de-velde .path3:before {
  content: "\E902";
  margin-left: -1.107421875em;
  color: #FFF; }

.icon-twitter:before {
  content: "\E903"; }

.icon-streep:before {
  content: "\E904"; }

.icon-ruimdienst:before {
  content: "\E905" !important;
  font-size: 25px !important; }

.icon-reinigingsdienst:before {
  content: "\E906" !important;
  font-size: 25px !important; }

.icon-phone:before {
  content: "\E907"; }

.icon-ontstoppingsdienst:before {
  content: "\E908" !important;
  font-size: 25px !important; }

.icon-instagram:before {
  content: "\E8FB"; }

.icon-half:before {
  content: "\E90A"; }

.icon-full:before {
  content: "\E90B"; }

.icon-facebook:before {
  content: "\E90C"; }

.icon-empty:before {
  content: "\E90D"; }

.icon-dropdown:before {
  content: "\E90E"; }

.icon-bol:before {
  content: "\E90F"; }

.icon-arrow:before {
  content: "\E911"; }

.icon-service .path1:before {
  content: "\E912";
  color: #de4905; }

.icon-service .path2:before {
  content: "\E913";
  margin-left: -1.9453125em;
  color: #333e98; }

/**
General layout
 */
body {
  background-color: #fff; }

a, button {
  cursor: pointer;
  -webkit-transition: color 140ms ease-in-out, background-color 140ms ease-in-out, border 140ms ease-in-out;
  transition: color 140ms ease-in-out, background-color 140ms ease-in-out, border 140ms ease-in-out; }

picture {
  display: block; }
  picture img {
    width: 100%; }

/**
General text
 */
body {
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.6;
  color: #090d2b; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #090d2b;
  font-weight: bold;
  font-family: "Montserrat", sans-serif; }

h1, .h1 {
  font-size: 48px; }
  @media (max-width: 767.98px) {
    h1, .h1 {
      font-size: 24px; } }

h2, .h2 {
  font-size: 46px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13; }
  @media (max-width: 991.98px) {
    h2, .h2 {
      font-size: 36px; } }
  @media (max-width: 767.98px) {
    h2, .h2 {
      font-size: 32px; } }

h5, .h5 {
  font-size: 26px; }

p {
  margin-bottom: 30px;
  line-height: 24px;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif; }
  p:last-child {
    margin-bottom: 0; }

.text {
  color: rgba(9, 13, 43, 0.75); }
  .text a {
    color: #204294;
    text-decoration: underline; }
    .text a:hover, .text a:focus {
      text-decoration: underline;
      color: #090d2b; }
  .text .h2, .text h2 {
    font-size: 26px; }
  .text .h3, .text h3 {
    font-size: 20px; }
  .text ul {
    padding: 0; }
    .text ul li {
      margin-bottom: 15px;
      padding-left: 1.5em;
      line-height: 24px;
      list-style: none; }
      .text ul li:before {
        content: "\E910" !important;
        font-family: "Icomoon";
        display: inline-block;
        margin-left: -2em;
        font-size: 10px;
        color: #f89728;
        margin-right: 1em; }
  .text ol {
    padding: 0;
    counter-reset: my-awesome-counter; }
    .text ol ul {
      counter-reset: my-awesome-counter; }
    .text ol li {
      counter-increment: my-awesome-counter;
      margin-bottom: 15px;
      padding-left: 1.5em;
      line-height: 24px;
      list-style: none; }
      .text ol li:before {
        content: counter(my-awesome-counter) ". ";
        display: inline-block;
        margin-left: -1.5em;
        margin-right: .7em;
        font-family: 400;
        color: #f89728; }

.text-light p, .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6,
.text-light .h1, .text-light .h2, .text-light .h3, .text-light .h4, .text-light .h5, .text-light .h6 {
  color: #fbffff; }

.text-light p, .text-light a {
  color: rgba(251, 255, 255, 0.75); }

/**
Page header
 */
.page-header {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #FFF;
  border-bottom: 1px solid #cbcee4;
  border-top: 3px solid #f89728;
  z-index: 4;
  position: relative;
  display: block; }
  @media (max-width: 767.98px) {
    .page-header {
      padding-top: 15px;
      padding-bottom: 15px; } }

.page-header__logo {
  display: block;
  width: 270px;
  outline: none; }
  @media (max-width: 1199.98px) {
    .page-header__logo {
      width: 220px; } }
  @media (max-width: 991.98px) {
    .page-header__logo {
      display: inline-block; } }
  @media (max-width: 767.98px) {
    .page-header__logo {
      width: 150px; } }

@media (max-width: 991.98px) {
  .main-nav {
    height: 0;
    padding: 0;
    overflow: hidden;
    -webkit-transition: height 150ms ease-out;
    transition: height 150ms ease-out; }
    .main-nav.open {
      height: calc(100vh - 84px);
      overflow: scroll;
      padding-bottom: 30px; } }

@media (max-width: 575.98px) {
  .main-nav {
    margin-left: -15px;
    margin-right: -15px; } }

@media (max-width: 991.98px) {
  .main-nav__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0;
    margin-top: 40px; } }

.main-nav__item {
  display: inline-block;
  margin-right: 55px; }
  .main-nav__item.main-nav__branding {
    font-size: 30px;
    position: relative;
    bottom: -5px; }
  .main-nav__item.is-active > a {
    color: #f89728; }
  @media (max-width: 1199.98px) {
    .main-nav__item {
      margin-right: 20px;
      padding-bottom: 0; }
      .main-nav__item.main-nav__branding {
        font-size: 18px; } }
  @media (max-width: 991.98px) {
    .main-nav__item {
      margin-right: 0;
      padding: 0; } }
  .main-nav__item:last-child {
    margin-right: 0; }

.main-nav__link {
  color: #090d2b;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
  display: block; }
  .is-active .main-nav__link.btn, .main-nav__link.btn:hover {
    color: #FFF; }
  @media (max-width: 991.98px) {
    .main-nav__link {
      line-height: 40px; } }
  @media (max-width: 575.98px) {
    .main-nav__link {
      padding: 0 15px; } }
  .main-nav__link:hover, .main-nav__link:focus {
    text-decoration: none;
    color: #f89728; }

.top-nav__item {
  display: inline-block;
  margin-bottom: 28px;
  margin-right: 55px;
  padding-top: 0; }
  .top-nav__item.is-active > a {
    color: #f89728; }
  .top-nav__item:last-child {
    margin-right: 0 !important; }
  @media (max-width: 991.98px) {
    .top-nav__item {
      margin-right: 0;
      margin-bottom: 0; }
      .top-nav__item:last-child {
        margin-top: 10px;
        margin-left: 0; } }
  @media (max-width: 575.98px) {
    .top-nav__item {
      margin-bottom: 0;
      margin-left: 15px; }
      .top-nav__item:last-child {
        margin-top: 10px;
        margin-left: 15px; } }

.top-nav__link {
  color: #090d2b;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 600; }
  @media (max-width: 991.98px) {
    .top-nav__link {
      font-size: 12px;
      line-height: 40px;
      display: block;
      padding: 0; } }
  .top-nav__link:hover, .top-nav__link:focus {
    text-decoration: none;
    color: #f89728; }

.has-subnav {
  position: relative; }
  @media (max-width: 991.98px) {
    .has-subnav {
      overflow: hidden; } }
  @media (max-width: 575.98px) {
    .has-subnav {
      margin-left: -15px;
      margin-right: -15px;
      padding: 0 15px; } }
  @media (min-width: 992px) {
    .has-subnav a {
      padding-right: 20px; } }
  .has-subnav:before {
    content: "";
    width: 6px;
    height: 6px;
    display: inline-block;
    border-bottom: 1px solid #204294;
    border-right: 1px solid #204294;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    right: 3px;
    top: 5px;
    -webkit-transition: opacity 140ms ease-in-out, -webkit-transform 140ms ease-in-out;
    transition: opacity 140ms ease-in-out, -webkit-transform 140ms ease-in-out;
    transition: opacity 140ms ease-in-out, transform 140ms ease-in-out;
    transition: opacity 140ms ease-in-out, transform 140ms ease-in-out, -webkit-transform 140ms ease-in-out; }
    @media (max-width: 991.98px) {
      .has-subnav:before {
        right: 5px;
        top: 15px; } }
    @media (max-width: 575.98px) {
      .has-subnav:before {
        right: 25px; } }
  .has-subnav.is-open:before {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
    @media (min-width: 992px) {
      .has-subnav.is-open:before {
        -webkit-transform: translateY(4px) rotate(-135deg);
                transform: translateY(4px) rotate(-135deg); } }

.sub-nav {
  -webkit-transition: opacity 140ms ease-in-out, -webkit-transform 140ms ease-in-out;
  transition: opacity 140ms ease-in-out, -webkit-transform 140ms ease-in-out;
  transition: transform 140ms ease-in-out, opacity 140ms ease-in-out;
  transition: transform 140ms ease-in-out, opacity 140ms ease-in-out, -webkit-transform 140ms ease-in-out; }
  @media (min-width: 992px) {
    .sub-nav {
      position: absolute;
      top: 9px;
      left: -45px;
      width: 250px;
      opacity: 0;
      visibility: hidden;
      z-index: 30; } }
  @media (max-width: 991.98px) {
    .sub-nav {
      height: 0;
      opacity: 0;
      margin: 0; }
      .is-open .sub-nav {
        height: auto;
        opacity: 1;
        visibility: visible; } }

.has-subnav:hover .sub-nav {
  height: auto;
  opacity: 1;
  visibility: visible; }
  @media (min-width: 992px) {
    .has-subnav:hover .sub-nav {
      padding-top: 39px; } }

.sub-nav__list {
  background: #f89728; }
  @media (max-width: 991.98px) {
    .sub-nav__list {
      padding: 0; } }

@media (min-width: 768px) {
  .sub-nav__item:first-child {
    padding-top: 10px; }
  .sub-nav__item:last-child {
    padding-bottom: 10px; }
  .sub-nav__item.is-active a {
    color: #FFF; } }

.sub-nav__link {
  color: rgba(251, 255, 255, 0.75);
  display: block;
  padding: 5px 45px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 35px;
  font-size: 12px; }
  @media (max-width: 991.98px) {
    .sub-nav__link {
      line-height: 40px;
      font-size: 12px;
      padding: 0 30px; } }
  .sub-nav__link:hover {
    text-decoration: none;
    color: #FFF;
    background: #f48608;
    cursor: pointer; }

.toggle-menu {
  width: 103px;
  padding: 4px 14px;
  border-radius: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #204294; }
  @media (min-width: 992px) {
    .toggle-menu {
      display: none; } }
  .toggle-menu span {
    font-size: 14px;
    color: #fbffff;
    font-family: "Montserrat", sans-serif;
    color: #FFF;
    font-weight: 500; }
  .toggle-menu:after {
    content: "\E914";
    font-family: "Icomoon";
    color: #FFF; }

@media (max-width: 991.98px) {
  .mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background: white;
    z-index: 40;
    position: relative; } }

.block-animation {
  position: relative; }
  .block-animation .service-icon {
    position: absolute;
    left: 238px;
    bottom: 5px; }

/**
Footer
 */
.page-footer {
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #f89728; }
  .page-footer .icon-van-de-velde {
    font-size: 65px; }
  .page-footer .icon-service {
    font-size: 30px;
    float: right;
    margin-top: -40px;
    margin-right: 60px; }
  .page-footer .icon-service .path1:before {
    color: #FFF; }
  .page-footer__logo {
    max-width: 300px; }
  .page-footer .phone-number {
    font-weight: 600;
    font-size: 18px; }
  .page-footer .phone-number, .page-footer .phone-number span {
    color: rgba(251, 255, 255, 0.75) !important; }
    .page-footer .phone-number .icon-phone:before, .page-footer .phone-number span .icon-phone:before {
      color: #204294; }

.page-footer__title {
  color: #fbffff;
  font-size: 14px;
  font-weight: bold; }

.page-footer__nav-list {
  -webkit-column-count: 2;
          column-count: 2; }
  @media (max-width: 575.98px) {
    .page-footer__nav-list {
      -webkit-column-count: 1;
              column-count: 1; } }

.page-footer__nav-item {
  margin-bottom: 10px; }

.page-footer__nav-link {
  color: rgba(251, 255, 255, 0.85);
  text-decoration: none;
  font-size: 14px; }
  .page-footer__nav-link:hover, .page-footer__nav-link:focus {
    text-decoration: underline;
    color: #fbffff; }

.socials {
  font-size: 20px; }
  .socials a {
    margin-right: 20px; }
    .socials a:hover {
      text-decoration: none; }

.innomedio {
  width: 84px;
  display: block;
  float: right; }

.flex-column-between {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }

@media (max-width: 575.98px) {
  .icons {
    width: 100%;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom; } }

.footer__icon-link {
  font-size: 20px;
  margin-right: 5px; }
  .footer__icon-link:hover {
    color: #FFF;
    text-decoration: none; }

/**
Forms
 */
.was-sent .form-content {
  display: none; }

.label-required:after {
  content: ' *';
  color: #f89728; }

.form-check-input {
  margin-top: 6px; }
  @media (max-width: 767.98px) {
    .form-check-input {
      margin-top: 5px; } }

.form-group.hidden {
  display: none; }

.form-group label:not(.btn) {
  height: 24px;
  font-size: 14px;
  line-height: 1.71;
  color: rgba(9, 13, 43, 0.75); }

.form-group input, .form-group textarea {
  -webkit-appearance: none;
  border: 1px solid #dee2e6;
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(9, 13, 43, 0.75);
  line-height: 1.2;
  font-size: 14px;
  border-radius: 3px;
  outline: none;
  background: transparent; }

.js-file-input {
  display: none; }

input[type=checkbox] {
  -webkit-appearance: none;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  outline: none;
  position: relative;
  display: inline-block;
  top: 2px;
  margin-right: 7px; }
  input[type=checkbox]:before {
    content: "";
    width: 10px;
    height: 10px;
    margin: 2px;
    position: absolute;
    border-radius: 1px; }
  input[type=checkbox]:checked:before {
    background: #f89728; }

/**
Button
 */
.btn {
  display: inline-block;
  line-height: 1;
  text-align: left;
  border-radius: 3px;
  border: none;
  margin-bottom: 0;
  width: auto;
  max-width: 100%;
  padding: 15px;
  min-width: 255px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #fbffff;
  -webkit-transition: color 140ms ease-in-out, border 140ms ease-in-out, background 140ms ease-in-out;
  transition: color 140ms ease-in-out, border 140ms ease-in-out, background 140ms ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none;
    outline: none; }
  .btn.is-loading:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-image: url(/build/images/loader.gif);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center center; }
  .btn:after {
    content: "";
    background-image: url("/build/images/Assets/drop.png");
    margin-left: 20px;
    font-size: 13px;
    width: 15px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 14px;
    background-size: contain;
    background-repeat: no-repeat; }
  .btn__sm {
    font-size: 12px;
    padding: 10px 45px 10px 15px;
    position: relative;
    min-width: 20px;
    min-width: unset; }
    .btn__sm:after {
      content: "";
      background-image: url("/build/images/Assets/drop.png");
      margin-left: 20px;
      font-size: 13px;
      width: 15px;
      height: 20px;
      position: absolute;
      right: 10px;
      top: 8px;
      background-size: contain;
      background-repeat: no-repeat; }

.btn--primary {
  color: #FFF;
  background: #f89728;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }
  .btn--primary:hover, .btn--primary:focus {
    background: #f48608;
    color: #FFF; }
  .btn--primary.is-loading:before {
    background-color: #f89728; }

.btn--secondary {
  background: #204294;
  color: #fbffff;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }
  .btn--secondary:hover, .btn--secondary:focus {
    background: #264fb1;
    color: #FFF; }

.btn--upload {
  background: #204294;
  max-width: 215px; }
  .btn--upload span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 125px;
    text-align: left;
    color: #fbffff; }
  .btn--upload:hover, .btn--upload:focus {
    background: #264fb1;
    color: #FFF; }

/**
Breadcrumbs
 */
.breadcrumbs__item {
  color: rgba(9, 13, 43, 0.75);
  text-decoration: none;
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 1.71; }
  .breadcrumbs__item:after {
    content: '';
    background-color: #f89728;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: 50%; }
  .breadcrumbs__item:last-child:after {
    content: none; }

.breadcrumbs__link {
  color: #090d2b; }

.card {
  display: block;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0), 0 15px 30px 0 rgba(0, 0, 0, 0);
          box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0), 0 15px 30px 0 rgba(0, 0, 0, 0);
  -webkit-transition: -webkit-box-shadow 140ms ease-in-out, -webkit-transform 140ms ease-in-out;
  transition: -webkit-box-shadow 140ms ease-in-out, -webkit-transform 140ms ease-in-out;
  transition: box-shadow 140ms ease-in-out, transform 140ms ease-in-out;
  transition: box-shadow 140ms ease-in-out, transform 140ms ease-in-out, -webkit-box-shadow 140ms ease-in-out, -webkit-transform 140ms ease-in-out;
  height: 100%; }
  .card.margin {
    margin: 20px; }
  @media (max-width: 991.98px) {
    .card {
      height: 570px; } }
  @media (max-width: 767.98px) {
    .card {
      height: 600px; } }
  @media (max-width: 575.98px) {
    .card {
      max-width: 300px;
      height: 550px;
      margin: auto; } }
  .card .card-img__container {
    overflow: hidden;
    height: 250px; }
  .card__img {
    position: relative; }
  .card__body {
    color: rgba(9, 13, 43, 0.75);
    margin-top: -28px;
    padding: 28px 20px;
    height: 310px;
    background: white; }
    .card__body p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    @media (min-width: 992px) {
      .card__body {
        height: auto; } }
  .card .author, .card__subtitle {
    color: rgba(9, 13, 43, 0.75);
    font-size: 10px;
    line-height: 2; }
  .card__title {
    text-transform: none;
    font-weight: 600; }
  .card__tag {
    background: #f89728;
    color: #FFF;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    top: -20px;
    left: 10px;
    z-index: 5;
    padding: 5px 10px;
    position: relative;
    display: inline-block;
    margin-right: 5px; }
  .card .devider {
    display: inline-block;
    margin: 0 7px; }
  @media (max-width: 991.98px) {
    .card {
      -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08), 0 15px 30px 0 rgba(0, 0, 0, 0.11);
              box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08), 0 15px 30px 0 rgba(0, 0, 0, 0.11); }
      .card .card-img__container, .card__body {
        height: auto; } }
  .card:hover {
    text-decoration: none !important;
    -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08), 0 15px 30px 0 rgba(0, 0, 0, 0.11);
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08), 0 15px 30px 0 rgba(0, 0, 0, 0.11);
    cursor: pointer;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }

/**
Cookie notice
 */
.cookie-notice {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  width: 350px;
  max-width: 100%;
  min-height: 192px;
  background-image: url("/build/images/cookie-bg.svg");
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
          align-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 8px 50px;
  color: rgba(251, 255, 255, 0.75); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .cookie-notice {
      padding: 100px 40px 0; } }
  .cookie-notice a {
    color: rgba(251, 255, 255, 0.75); }
    .cookie-notice a:hover {
      color: #fbffff; }
  .cookie-notice .js-close-cookie {
    cursor: pointer;
    position: absolute;
    right: 39px;
    top: 41px;
    color: #f89728;
    font-size: 10px;
    background: white;
    border-radius: 30px;
    padding: 5px;
    font-weight: 900; }

.banner {
  height: 400px; }
  .banner .banner__text p, .banner .banner__text {
    font-size: 16px !important; }
  @media (max-width: 575.98px) {
    .banner.review-is-active {
      background-size: auto 430px;
      background-repeat: no-repeat;
      background-position: top;
      height: 500px; } }
  .banner.review-is-active .banner__text p, .banner.review-is-active .banner__text {
    font-size: 14px !important; }

.header__text {
  width: 350px;
  margin-bottom: 40px;
  padding: 25px 35px;
  background-color: #f89728;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  border-radius: 3px; }
  .header__text p {
    font-size: inherit; }
  .header__text h2 {
    color: #fff;
    font-size: 26px; }
  .header__text ul {
    margin-bottom: 0;
    list-style: none;
    padding-left: 0; }
  .header__text li {
    margin-bottom: 10px; }
    .header__text li:before {
      content: '';
      background-image: url("/build/images/Assets/drop.png");
      width: 13px;
      height: 22px;
      background-size: 100% 100%;
      margin-right: 15px;
      display: inline-block;
      vertical-align: middle; }

.google-review {
  background-size: 100%;
  position: relative;
  height: 350px;
  width: 100%; }
  @media (max-width: 767.98px) {
    .google-review {
      height: 334px; } }
  .google-review:before {
    content: "";
    background-image: url("/build/images/asset-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    margin-left: -30px;
    position: absolute;
    width: 460px;
    height: 100%;
    z-index: 4; }
    @media (max-width: 767.98px) {
      .google-review:before {
        margin: 0;
        height: 500px;
        top: 0; } }
    @media (max-width: 767.98px) {
      .google-review:before {
        height: 350px; } }
    @media (max-width: 575.98px) {
      .google-review:before {
        height: 333px; } }

.google-review__contents {
  width: calc(100% + 33px);
  position: relative;
  margin-left: -53px;
  margin-top: 16px;
  padding: 55px 0;
  display: block;
  height: 100%;
  z-index: 7; }
  @media (min-width: 992px) {
    .google-review__contents {
      padding: 40px 80px; } }
  @media (min-width: 1200px) {
    .google-review__contents {
      padding: 40px 120px; } }
  @media (max-width: 767.98px) {
    .google-review__contents {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-align-content: center;
          -ms-flex-line-pack: center;
              align-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      margin: 0;
      padding: 0 90px; } }
  @media (max-width: 575.98px) {
    .google-review__contents {
      border: none;
      max-width: 380px;
      padding: 0 20px; } }

.google-review__stars, .google-review__author {
  text-align: center;
  display: block;
  color: rgba(251, 255, 255, 0.75); }

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url(/build/fonts/slick.ced611da.eot);
  src: url(/build/fonts/slick.ced611da.eot?#iefix) format("embedded-opentype"), url(/build/fonts/slick.b7c9e1e4.woff) format("woff"), url(/build/fonts/slick.d41f55a7.ttf) format("truetype"), url(/build/images/slick.f97e3bbf.svg#slick) format("svg"); }

.logo-slider__item {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  margin: 0 auto;
  max-height: 60px;
  width: auto !important; }

.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 100;
  background: transparent;
  top: 34%; }
  .slick-prev:before, .slick-next:before {
    color: #f89728;
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 10px;
    border: 0; }

@media (max-width: 767.98px) {
  .slick-list {
    width: calc(100% - 100px);
    margin: auto; } }

.slick-prev {
  left: -30px; }
  .slick-prev:before {
    content: '\2190'; }
  @media (max-width: 767.98px) {
    .slick-prev {
      left: 23px; } }

.slick-next {
  right: -30px; }
  .slick-next:before {
    content: '\2192'; }
  @media (max-width: 767.98px) {
    .slick-next {
      right: 23px; } }

.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '\2022';
  text-align: center;
  color: #204294;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #f89728; }

.section-faq .faq-question {
  border-bottom: solid 1px #cbcee4; }
  .section-faq .faq-question .anwser {
    display: none; }
  .section-faq .faq-question h2 {
    margin-bottom: 0; }
  .section-faq .faq-question:hover h2 {
    color: #f89728;
    cursor: pointer; }

.section-faq .faq-question:nth-of-type(odd) {
  background-color: #cbcee419; }

.table__row {
  min-height: 60px;
  padding: 10px 0;
  border-bottom: 1px solid #cbcee4;
  text-decoration: none !important;
  color: #090d2b !important; }
  .table__row:hover {
    color: #f89728 !important; }
  .table__row:nth-of-type(even) {
    background-color: #cbcee419; }

.table__cell {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  letter-spacing: normal; }

.table__cell-light {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400; }

.table__cell-faded {
  color: #090d2b;
  opacity: .4; }

.table__cell-regular {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600; }

.table__cell-bold {
  font-weight: 800;
  font-family: "Montserrat", sans-serif; }

.option {
  position: relative;
  display: block;
  text-decoration: none !important; }
  .option span {
    color: #f89728;
    text-decoration: underline; }
  .option h3 {
    -webkit-transition: color 140ms ease-in-out;
    transition: color 140ms ease-in-out; }
  .option .show-on-hover {
    opacity: 0;
    -webkit-transition: opacity 140ms ease-in-out;
    transition: opacity 140ms ease-in-out; }
    @media (max-width: 991.98px) {
      .option .show-on-hover {
        opacity: 1; } }
  .option:hover {
    cursor: pointer;
    text-decoration: none; }
    .option:hover .option__icon:before {
      color: #204294; }
    .option:hover a {
      color: #f89728; }
    .option:hover h3 {
      color: #204294; }
    .option:hover .show-on-hover {
      opacity: 1; }

.option__icon {
  font-size: 30px;
  position: absolute;
  left: -40px;
  top: 0;
  text-decoration: none !important; }
  .option__icon:before {
    content: "\E910";
    font-family: "Icomoon";
    font-size: initial;
    position: absolute;
    top: 0; }
  @media (max-width: 1199.98px) {
    .option__icon {
      left: -38px; } }
  .option__icon:before {
    color: #f89728;
    -webkit-transition: color 140ms ease-in-out;
    transition: color 140ms ease-in-out; }

.options-grid .option {
  overflow: hidden;
  padding: 40px;
  height: 300px;
  margin-bottom: 40px;
  background: #fefaf9;
  -webkit-box-shadow: 0 -12px 36px -8px rgba(0, 0, 0, 0.02), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 30px 40px -12px rgba(0, 0, 0, 0.25);
          box-shadow: 0 -12px 36px -8px rgba(0, 0, 0, 0.02), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 30px 40px -12px rgba(0, 0, 0, 0.25); }
  .options-grid .option__body {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-align-content: flex-end;
        -ms-flex-line-pack: end;
            align-content: flex-end;
    -webkit-transition: -webkit-transform 140ms ease-in-out;
    transition: -webkit-transform 140ms ease-in-out;
    transition: transform 140ms ease-in-out;
    transition: transform 140ms ease-in-out, -webkit-transform 140ms ease-in-out; }
    .options-grid .option__body .text {
      margin-right: 40px; }
    .options-grid .option__body .text p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .options-grid .option .btn {
    text-decoration: none; }
  .options-grid .option__title {
    font-size: 28px; }
  .options-grid .option:hover {
    background: #f89728; }
    .options-grid .option:hover .option__title, .options-grid .option:hover .text {
      color: #FFF; }
    .options-grid .option:hover .option__body {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px); }
    .options-grid .option:hover .btn {
      display: block; }

.technique__header {
  margin-bottom: 10px; }

.technique__icon {
  margin-right: 10px; }

.technique__link {
  color: #090d2b;
  text-decoration: underline; }

